import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { FaRegLightbulb } from 'react-icons/fa'
import { useStaticQuery, graphql } from 'gatsby'

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query TestimonialQuery {
      allFile(
        filter: {
          ext: { regex: "/(jpg)|(png)|(jpeg)/"},
          name: {in: ["testimonial1", "testimonial2"]}
        }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  return (
    <TestimonialsContainer>
      <TopLine>
        Testimonials
      </TopLine>
      <Description>
        What people are saying
      </Description>
      <ContentWrapper>
        <Column1>
          <Testimonial>
            <IoMdCheckmarkCircleOutline css={`color: #E8B863; font-size: 2rem; margin-bottom: 1rem;`}/>
            <h3>Sean Michales</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus vitae quam deleniti alias.</p>
          </Testimonial>
          <Testimonial>
            <FaRegLightbulb css={`color: #E8B863; font-size: 2rem; margin-bottom: 1rem;`}/>
            <h3>Cole Michales</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus vitae quam deleniti alias.</p>
          </Testimonial>
        </Column1>
        <Column2>
          {data.allFile.edges.map((image, key) => (
            <Images
              key={key}
              image={getImage(image.node)}
              alt={"testimonial"+(1+key)}
            />
          )
          )}
        </Column2>
      </ContentWrapper>
    </TestimonialsContainer>
  )
}

export default Testimonials

const TestimonialsContainer = styled.div`
  width: 100%;
  color: ${props => props.theme.textColor};
  background: ${props => props.theme.pageBackgroundSecondary};
  padding: 5rem calc((100vw - 1300px) / 2);
  height: 100%;
`

const TopLine = styled.p`
  color: ${props => props.theme.titleColor};
  font-size: 1rem;
  padding-left: 2rem;
  margin-bottom: 0.75rem;
`

const Description = styled.p`
  text-align: start;
  padding-left: 2rem;
  margin-bottom: 4rem;
  font-size: clamp(1.5rem, 5vw, 2rem);
  font-weight: bold;
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Column1 = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
`

const Testimonial = styled.div`
  padding-top: 1rem;
  padding-right: 2rem;

  h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-style: italic;
  }

  a {
    color: ${props => props.theme.titleColor};
  }
`

const Column2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
  grid-gap: 10px;

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const Images = styled(GatsbyImage)`
  border-radius: 10px;
  height: 100%;
`
