import React from 'react'
import styled from 'styled-components'
import { Heading } from './Heading'
import AboutH from "../assets/images/heading_about_us.svg"

const About = ({heading}) => {
  return (
    <BrandsContainer id="about">
      <Heading src={AboutH} alt={heading} />
      <TopLine>
        Management & Booking
      </TopLine>
      <Description>
        We rise lifting others.
      </Description>
    </BrandsContainer>
  )
}

export default About

const BrandsContainer = styled.div`
  width: 100%;
  color: ${props => props.theme.textColor};
  background: ${props => props.theme.pageBackgroundSecondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem calc((100vw - 1300px) / 2);
`

const TopLine = styled.p`
  color: ${props => props.theme.titleColor};
  text-align: center;
  font-size: clamp(1.5rem, 5vw, 2rem);
  padding-left: 2rem;
  margin-bottom: 0.75rem;
`

const Description = styled.p`
  text-align: center;
  font-size: 1rem;
  padding-left: 2rem;
  margin-bottom: 4rem;
  font-weight: bold;
`
