import React from 'react'
import { IoEarthSharp, IoAirplaneOutline, IoTimer } from 'react-icons/io5'
import { IoMdCash } from 'react-icons/io'

export const StatsData = [
  {
    icon: <IoEarthSharp color='#047bf1' />,
    title: "Over 100 Associates",
    desc: "Meet over..."
  },
  {
    icon: <IoAirplaneOutline color='#f3a82e' />,
    title: "3 Countries",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
  },
  {
    icon: <IoTimer color='#f34f2e' />,
    title: "Fast Support",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
  },
  {
    icon: <IoMdCash color='#3af576' />,
    title: "Best Deals",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
  }
]