import * as React from "react"
import About from "../components/About"
import Brands from "../components/Brands"
import Email from "../components/Email"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import People from "../components/People/People"
import Seo from "../components/seo"
import Stats from "../components/Stats"
import Testimonials from "../components/Testimonials"

const IndexPage = ({ location, data }) => {

  return (
    <Layout location={location} hero={true}>
      <Seo title="Home" />
      <Hero />
      <About heading="About" />
      <People heading="People" />
      {/* <Testimonials /> */}
      {/* <Stats /> */}
      <Brands heading="Brands" />
      <Email />
    </Layout>
  )
}

export default IndexPage
