import React from 'react'
import SheinLogo from "../assets/images/brands/shein_logo.svg"
import CklassLogo from "../assets/images/brands/cklass_logo.svg"
import AutoCinemaCoyote from "../assets/images/brands/autocinema_logo.svg"
import MercadoRevolucion from "../assets/images/brands/mercado_logo.svg"

export const BrandsData = [
  {
    img: <img src={SheinLogo} alt='Shein' />,
  },
  {
    img: <img src={CklassLogo} alt='CKlass' />,
  },
  {
    img: <img src={AutoCinemaCoyote} alt='Auto Cinema Coyote' />,
  },
  {
    img: <img src={MercadoRevolucion} alt='Mercado Revolución' />,
  }
]