import React from 'react'
import styled from 'styled-components'
import EmailBg from '../assets/images/email.jpg'
import { Button } from './Button'

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <EmailBtn
    type="submit"
    primary="true"
    round="true"
    target="_blank"
    href={`mailto:${email}${params}`}>{children}</EmailBtn>;
};


const Email = () => {
  return (
    <EmailContainer id="contact">
      <EmailContent>
        <h1>Contact Us</h1>
        <p>We'll reach out</p>
        <form action="#">
          <FormWrap>
            {/* <label htmlFor="email">
              <input type="email" placeholder="Enter your email" id="email" />
            </label> */}
              <Mailto email="info@dazzling.mx" subject="Solicitar Información" body="hola!">
                Contact
              </Mailto>
          </FormWrap>
        </form>
      </EmailContent>
    </EmailContainer>
  )
}

export default Email

const EmailContainer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0 ,0 ,0 , 0.1) 0%,
    rgba(0 ,0 ,0 , 0.3) 35%,
    rgba(0 ,0 ,0 , 0.5) 100%
  ),
  url(${EmailBg}) no-repeat center;
  background-size: cover;
  height: 450px;
  width: 100%;
  padding: 5rem calc((100vw - 1300px) / 2);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmailContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: clamp(1rem, 5vw, 3rem);
    padding: 0 1rem;
  }

  p {
    text-align: center;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    padding: 0 1rem;
    margin-bottom: 2rem;
  }

  form {
    z-index: 10;
  }
`

const FormWrap = styled.div`
  input {
    padding: 1rem 1.5rem;
    outline: none;
    width: 350px;
    height: 48px;
    border-radius: 50px;
    border: none;
    margin-right: 1rem;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    input {
      margin-bottom: 1rem;
      width: 100%;
      margin-right: 0;
    }
  }
`

const EmailBtn = styled.a`
  background: ${({primary}) => (primary ? '#fff' : '#E8B863')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '16px 40px' : '10px 32px')};
  color: ${({primary}) => (primary ? '#000' : '#fff')};
  font-size: ${({big}) => (big ? '20px' : '16px')};
  outline: none;
  border: none;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transform: 0.3s !important;
  border-radius: ${({round}) => (round ? '50px' : 'none')};

  &:hover {
    background: ${({primary}) => (primary ? '#E8B863' : '#fff')};
    color: ${({primary}) => (primary ? '#fff' : '#000')};
    transform: translateY(-2px);
  }
`