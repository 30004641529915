import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Heading } from "../Heading"
import { ImUser, ImInstagram, ImMusic } from "react-icons/im"
import { FaHashtag, FaTiktok } from "react-icons/fa"
import TalentsH from "../../assets/images/heading_talents.svg"
import ArtistsH from "../../assets/images/heading_artists.svg"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

const responsive = {
  0: { items: 1 },
  900: { items: 2 },
  1200: { items: 3 },
}

const People = ({ heading }) => {
  const [isRendered, setIsRendered] = useState(false)

  const data = useStaticQuery(graphql`
    query PeopleQuery {
      allDataJson {
        edges {
          node {
            talents {
              alt
              name
              userIG
              followersIG
              userTT
              followersTT
              info
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            artists {
              alt
              name
              userIG
              followersIG
              userTT
              followersTT
              info
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  function getPeople(data, type) {
    const peopleArray = []
    data.allDataJson.edges[0].node[type].forEach((item, index) => {
      peopleArray.push(
        <PeopleCard key={index} className="item" data-value={index}>
          <PeopleImg image={getImage(item.img)} alt={item.alt} />
          <PeopleInfoTop>
            <TextWrap>
              {item.info.includes("Artist") ? <ImMusic /> : <ImUser />}
              <PeopleTitle>{item.name}</PeopleTitle>
            </TextWrap>
          </PeopleInfoTop>
          <PeopleInfo>
            <TextWrap>
              <ImInstagram />
              <PeopleTitle>{item.userIG}</PeopleTitle>
              <PeopleTitle>{item.followersIG}</PeopleTitle>
            </TextWrap>
            <TextWrap>
              <FaTiktok />
              <PeopleTitle>{item.userTT}</PeopleTitle>
              <PeopleTitle>{item.followersTT}</PeopleTitle>
            </TextWrap>
          </PeopleInfo>
          <PeopleInfoBottom>
            <TextWrap>
              <FaHashtag />
              <PeopleTitle>{item.info}</PeopleTitle>
            </TextWrap>
          </PeopleInfoBottom>
        </PeopleCard>
      )
    })
    return peopleArray
  }

  useEffect(() => {
    setIsRendered(true)
  }, [])

  if (!isRendered) {
    return <h3>Loading...</h3>
  } else
    return (
      <div>
        <PeopleContainer>
          <div style={{ paddingTop: "2rem" }} id="talents" />
          <Heading src={TalentsH} alt={heading} />
          <SliderContainer>
            <AliceCarousel
              autoPlay
              // autoPlayControls
              autoPlayStrategy="none"
              autoPlayInterval={3000}
              animationDuration={3000}
              animationType="fadeout"
              infinite
              items={getPeople(data, "talents")}
              paddingLeft={50}
              responsive={responsive}
              disableButtonsControls
              disableDotsControls
            />
          </SliderContainer>
          <div style={{ paddingTop: "2rem" }} id="artists" />
          <Heading src={ArtistsH} alt={heading} />
          <SliderContainer>
            <AliceCarousel
              autoPlay
              // autoPlayControls
              // autoPlayStrategy="none"
              // autoPlayInterval={3000}
              // animationDuration={3000}
              // animationType="fadeout"
              // infinite
              items={getPeople(data, "artists")}
              paddingLeft={50}
              responsive={responsive}
              disableButtonsControls
              disableDotsControls
            />
          </SliderContainer>
        </PeopleContainer>
      </div>
    )
}

export default People

const PeopleContainer = styled.div`
  padding: 5rem calc((100vh - 1300px) / 2);
  color: ${props => props.theme.textWhite};
  background: ${props => props.theme.pageBackground};
`

const SliderContainer = styled.div`
  margin-left: 10%;
  margin-right: 10%;

  @media screen and (max-width: 600px) {
    margin-left: 5%;
    margin-right: 5%;
  }

  @media screen and (max-width: 400px) {
    margin-left: 0%;
    margin-right: 0%;
  }
`

const PeopleCard = styled.div`
  line-height: 2;
  position: relative;
  border-radius: 10px;
  transition: 0.2s ease;
  margin-bottom: 40px;

  @media screen and (max-width: 1200px) {
    width: 400px;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    width: 350px;
    height: auto;
  }

  @media screen and (max-width: 500px) {
    width: 300px;
    height: auto;
  }

  max-width: 300px;
`

const PeopleImg = styled(GatsbyImage)`
  height: 100%;
  max-width: 100%;
  position: relative;
  border-radius: 10px;
  filter: brightness(70%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    filter: brightness(100%);
  }
`

const PeopleInfoTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 2rem;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  background: ${props => props.theme.navBackgroundTrans};
  background: rgba(232, 184, 99, 0.8);
  width: auto;
  /* margin: 1rem; */
`

const PeopleInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 1rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  bottom: 2.5rem;
  left: 0px;
  background: ${props => props.theme.navBackgroundTrans};
`

const PeopleInfoBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: rgba(232, 184, 99, 0.8);
`

const TextWrap = styled.div`
  display: flex;
  align-items: center;
`

const PeopleTitle = styled.div`
  font-weight: 400;
  font-size: 1rem;
  margin-left: 0.5rem;
`
