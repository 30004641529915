import React from 'react'
import styled from 'styled-components'
import { BrandsData } from '../data/BrandsData'
import { Heading } from './Heading'
import BrandsH from "../assets/images/heading_brands.svg"

const Brands = ({heading}) => {
  return (
    <BrandsContainer id="brands">
      <Heading src={BrandsH} alt={heading} />
        <Wrapper>
          {BrandsData.map((item, index) => {
            return (
            <BrandsBox key={index} >
              {item.img}
            </BrandsBox>
            )
          })}
        </Wrapper>
    </BrandsContainer>
  )
}

export default Brands

const BrandsContainer = styled.div`
  width: 100%;
  color: ${props => props.theme.textColor};
  background: ${props => props.theme.pageBackgroundSecondary};
  padding: 4rem calc((100vw - 1300px) / 2);
`

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
  padding: 1rem 2rem;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
`

const BrandsBox = styled.div`
  background-color: ${props => props.theme.brandBackground};
  border-radius: 10px;
  padding: 8px;
  font-size: 8px;
  margin-right: auto;
  margin-left: auto;

  img {
    height: 150px;
    width: auto;
  }
`
