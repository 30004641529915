import styled from 'styled-components'

export const Heading = styled.img`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  padding-top: 2rem;
  margin-bottom: 2rem;
  color: ${props => props.theme.titleColor};
  height: auto;
  width: 300px;
  margin-left: calc(50% - 150px);
`