import styled from 'styled-components'
import { Link } from 'gatsby'

export const Button = styled(Link)`
  background: ${({primary}) => (primary ? '#fff' : '#E8B863')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '16px 40px' : '10px 32px')};
  color: ${({primary}) => (primary ? '#000' : '#fff')};
  font-size: ${({big}) => (big ? '20px' : '16px')};
  outline: none;
  border: none;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transform: 0.3s !important;
  border-radius: ${({round}) => (round ? '50px' : 'none')};

  &:hover {
    background: ${({primary}) => (primary ? '#E8B863' : '#fff')};
    color: ${({primary}) => (primary ? '#fff' : '#000')};
    transform: translateY(-2px);
  }
`