import React from "react"
import styled from "styled-components"
import { Button } from "./Button"
import Video from "../assets/videos/videobg.mp4"
import logo from "../assets/images/dazz.png"

const Hero = () => {
  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg src={Video} type="video/mp4" autoPlay loop muted playsInline />
      </HeroBg>
      <HeroContent>
        <HeroItems>
          <LogoImg src={logo} alt="dazzling.mx" />
          <Button primary="true" big="true" round="true" to="#contact">
            Book now
          </Button>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero

const HeroContainer = styled.div`
  background-color: #0c0c0c;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  height: 100vh;
  padding: 0 1rem;
  position: relative;
  margin-top: -80px;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0, 0.2) 0%,
        gba(0, 0, 0, 0, 0.2),
        100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0, 0.2) 0%, transparent, 100%);
  }
`

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`

const HeroContent = styled.div`
  z-index: 3;
  height: calc(100vh -80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px) / 2);
`

const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  color: #fff;
  line-height: 1.1;
  font-weight: bold;
`

const LogoImg = styled.img`
  margin-bottom: 1rem;
  max-width: calc(100vw / 2);
  min-width: 300px;
  filter: (70%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    filter: brightness(100%);
  }
`

// const HeroH1 = styled.div`
//   font-size: clamp(1.5rem, 6vw, 4rem);
//   margin-bottom: 1.5rem;
//   letter-spacing: 3px;
//   padding: 0.1rem;
// `

// const HeroP = styled.div`
//   font-size: clamp(1rem, 3vw, 3rem);
//   margin-bottom: 2rem;
//   font-weight: 100;
// `
